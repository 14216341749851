
<template>
  <div id="app">
    <Nav/>
    <keep-alive>
      <router-view/>
    </keep-alive>
    <Footer/>
  </div>
</template>

<script>
import Footer from '@/components/Footer.vue'
import Nav from '@/components/Nav.vue'

export default {
  name: "App",
  data: function() {
    return {
      mobileMenuIsActive: false
    }
  },
  components: {
    Footer,
    Nav
  },
  methods: {
    toggleMobileMenu: function() {
      this.mobileMenuIsActive = !this.mobileMenuIsActive;
    }
  }
}
</script>

<style lang="scss">

@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;700;800&display=swap');

#app {
  font-family: -apple-system,'Poppins', system-ui, BlinkMacSystemFont, "Segoe UI", Roboto, Ubuntu;
  text-align: center;
  color: var(--white);
  background-color: var(--background);
  display: flex;
  min-height: 100vh;
  flex-direction: column;
  justify-content: space-between;
  overflow-x: hidden !important;
  max-width: 100% !important;

  .page {
    min-height: calc(100vh - 80px - 170px);
  }
}
</style>
