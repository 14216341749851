import Vue from 'vue'
import Router from 'vue-router'
import Home from './pages/Home.vue'


Vue.use(Router)

export default new Router({
  scrollBehavior() {
    return { x: 0, y: 0 };
  },
  mode: 'history',
  routes: [
    {
      path: '/',
      name: 'home',
      component: Home
    },
    {
      path: '/events',
      name: 'events',
      component: () => import('./pages/Events.vue')
    },
    {
      path: '/download',
      name: 'download',
      component: () => import('./pages/Download.vue')
    },
    {
      path: '/support',
      name: 'support',
      component: () => import('./pages/Support.vue')
    },
    {
      path: '/privacy',
      name: 'privacy',
      component: () => import('./pages/Privacy.vue')
    },
    {
      path: '/terms',
      name: 'terms',
      component: () => import('./pages/Terms.vue')
    },
    {
      path: '/contact',
      name: 'contact',
      component: () => import('./pages/Contact.vue')
    },
    {
      path: '/stream/:streamId',
      name: 'stream',
      component: () => import('./pages/Livestream.vue')
    }
  ]
})
