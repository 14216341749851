<template>
  <footer class="wrapper">
    <div class="row">
      <div class="col-auto d-flex">
        <router-link to="/">
          <img src="../assets/logo.svg" class="logo" />
        </router-link>
      </div>
      <div class="col-auto ml-auto">
        <ul class="social-links mb-0">
          <li>
            <a href="https://www.youtube.com/channel/UCxoEtXNIOGhc9rI9bkBZwng">
              <img src="../assets/youtube.svg" alt="" />
            </a>
          </li>
          <li>
            <a href="https://www.instagram.com/sypher.app/?hl=en">
              <img src="../assets/insta.svg" alt="" />
            </a>
          </li>
        </ul>
      </div>
    </div>
    <div class="row mt-4 justify-content-lg-between">
      <div class="col-12 col-lg-auto">
        <ul class="m-0 pl-0 links">
          <li><router-link to="/contact">Contact</router-link></li>
          <li><router-link to="/privacy">Privacy Policy</router-link></li>
          <li><router-link to="/terms">Terms</router-link></li>
        </ul>
      </div>
      <div class="col-12 col-lg-auto">
        <p class="mt-3 small text-gray-light text-left m-lg-0 ml-lg-auto">
          &copy; 2020 SYPHER Music Inc. All Rights Reserved
        </p>
      </div>
    </div>
  </footer>
</template>

<script>
export default {
  name: "Footer"
};
</script>

<style lang="scss" scoped>

footer {
  padding-top: 1rem;
  padding-bottom: 1rem;
  z-index: 1028;
  background-color: var(--background);

  border-top: 1px solid var(--border);

  .logo {
    width: 130px;
    height: 40px;
  }

  .row:nth-child(2) {
    margin-top: 40px;
  }

  .links li {
    list-style: none;
    display: block;
    text-align: left;
    font-size: 0.9rem;
    margin-right: 1rem;
    padding: 2px 0;

    a {
      color: var(--gray-light);
      font-weight: 400;
    }
  }

  .social-links li {
    background-color: var(--gray);
    padding: 7px;
    border-radius: 50%;
    margin: 0;
    width: 40px;
    height: 40px;
    list-style: none;
    display: inline-block;
    margin-left: 1rem;

    img {
      width: 18px;
    }
  }

  @media only screen and (min-width: 992px) {
    padding-top: 30px;
    padding-bottom: 30px;

    .links li {
      display: inline-block;
    }
  }

  @media only screen and (min-width: 1200px) {
    padding-top: 40px;
    padding-bottom: 40px;
  }
}
</style>
