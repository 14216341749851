<template>
  <div>
    <div class="hide-apple-blur"></div>
    <b-navbar fixed="top" id="nav" class="wrapper">
      <div class="d-nav-bg d-none d-lg-block"></div>
      <b-navbar-brand to="/" @click="toggleMobileMenu">
        <img src="../assets/logo.svg" alt="SYPHER Music Logo">
      </b-navbar-brand>
      
      <div class="links ml-auto">
        <router-link class="link" to="/download">
            <div class="position">
              <div class="nav-link">Download</div>
            </div>
            <div class="underline"></div>
        </router-link>

        <!-- <router-link class="link" to="/events">
          <div class="position">
            <div class="nav-link">Events</div>
          </div>
          <div class="underline"></div>
        </router-link> -->

        <router-link class="link" to="/support">
            <div class="position">
              <div class="nav-link">Support</div>
            </div>
            <div class="underline"></div>
        </router-link>

        <router-link class="link" to="/contact">
            <div class="position">
              <div class="nav-link">Contact</div>
            </div>
            <div class="underline"></div>
        </router-link>
      </div>

      <button class="hamburger hamburger--emphatic ml-auto d-lg-none" type="button" @click="toggleMobileMenu"
        :class="{ 'is-active': mobileMenuIsActive }" data-toggle="modal" data-target="#menu-modal">
        <span class="hamburger-box">
          <span class="hamburger-inner"></span>
        </span>
      </button>
    </b-navbar>

    <div class="mobile-menu right d-lg-none" :class="{ 'is-active': mobileMenuIsActive }">
      <div class="d-block mx-auto w-100 px-5">
        <div class="mb-4">
          <router-link to="/download" @click.native="toggleMobileMenu">Download</router-link>
        </div>
        <!-- <div class="mb-4">
          <router-link to="/events" @click.native="toggleMobileMenu">Events</router-link>
        </div> -->
        <div class="mb-4">
          <router-link to="/support" @click.native="toggleMobileMenu">Support</router-link>
        </div>
        <div class="mb-4">
          <router-link to="/contact" @click.native="toggleMobileMenu">Contact</router-link>
        </div>
      </div>
      <Footer></Footer>
    </div>
  </div>
</template>

<script>

  import Footer from '@/components/Footer.vue'

  /* eslint-disable */
  export default {
    name: 'Nav',
    data: function () {
      return {
        mobileMenuIsActive: false
      }
    },
    components: {
      Footer
    },
    methods: {
      toggleMobileMenu: function () {
        this.mobileMenuIsActive = !this.mobileMenuIsActive

        const els = document.getElementsByClassName("nav-spcr");

        if (this.mobileMenuIsActive)
        {
          for (var i = 0; i < els.length; i++) {
            els[i].classList.add('active');
          }

        }
        else
        {
           els.forEach(el => {
            el.classList.remove('active');
          })
        }
      }
    }
  }
</script>

<style lang="scss" scoped>
span.hamburger-inner{
  background: #FFFFFF !important;
  width: 20px;
  height: 2.5px;
}

span.hamburger-inner::before{
  background: #FFFFFF !important;
  width: 20px;
  height: 2.5px;
}

span.hamburger-inner::after{
  background: #FFFFFF !important;
  width: 20px;
  height: 2.5px;
}
.navbar {
  height: 60px;
  border-bottom: 1px solid var(--border);
  background-color: var(--background);

  .hamburger {
    padding-right: 6px;
    &:focus {
      outline: none;
    }

    .hamburger-box {
      margin-top: 7px;
    }
  }

  .navbar-brand {
    padding: 0;

    img {
      width: 170px;
    }
  }

  // MARK: Desktop Styles
  @media only screen and (min-width: 992px) {

    height: 80px;
    padding-top: 30px;
    padding-bottom: 30px;

    .links {

      height: 40px;
      position: relative;

      a {
        color: #FFFFFF;
        font-size: 1rem;
        font-weight: 600;
        padding: 15px;
        display: block;
        transition-duration: 0.3s;

        &:hover {
          text-decoration: none;
        }
      }

      .router-link-active {
      
       .underline {
          right: 15%;
          left: auto;
          width: 65%;
        }
      }
    }

    .nav-link {
      text-align: right;
    }

    .link {
      height: 42px;
      display: inline-block !important;
      position: relative;
      transition-duration: 0.3s;
      color: rgba($color: #FFFFFF, $alpha: 0.9);

     
      &:nth-child(1) {
        width: 140px;
      }

      &:nth-child(2) {
        width: 110px;
      }
      
      &:nth-child(3) {
        width: 115px;
      }

      &.active {
        .underline {
          width: 70%;
        }

        a {
          color: #FFFFFF;
          transform: translateY(8px);
        }
      }

      .position {
        position: absolute;
        display: block;
        height: 100%;
        width: 100%;
        top: 0;
        left: -3px;
      }

      .underline {
        position: absolute;
        transition-property: width;
        transition-duration: 0.3s;
        transition-timing-function: ease-out;
        left: 15%;
        right: auto;
        width: 0;
        bottom: 0;
        height: 3px;
        background-color: #ED6A00;
      }

      &:hover {
        a {
          transform: translateY(8px);
          color: #FFFFFF;
        }

        .underline {
          right: 15%;
          left: auto;
          width: 65%;
        }
      }
    }

    .navbar-brand img {
      height: 42px;
    }
  }
}

//MARK: Mobile Slide Out Menu

.mobile-menu {

  height: 100%;
  width: 100%;
  position: fixed;
  top: 0;
  right: 0;
  background-color: var(--background);
  text-align: center;
  z-index: 1029;
  pointer-events: none;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  right: -100px;
  opacity: 0;
  -webkit-transition: opacity 0.15s ease-in, right 0.15s linear;
  -moz-transition: opacity 0.15s ease-in, right 0.15s linear;
  -o-transition: opacity 0.15s ease-in, right 0.15s linear;
  transition: opacity 0.15s ease-in, right 0.15s linear;
  padding-top: 20vh;

  a {
    display: inline-block;
    margin: auto;
    font-size: 1.5rem;
    font-weight: 600;
    margin-bottom: 2rem;
    color: #fff;
    font-weight: 600;
    position: relative;

    &.router-link-exact-active {
      &::after {
        position: absolute;
        content: '';
        height: 3px;
        bottom: -6px;
        margin: 0 auto;
        right: 0;
        width: calc(100% + 12px);
        background: var(--primary);
      }
    }

    &:hover {
      text-decoration: none;
    }
  }

  .btn-primary {
    margin-bottom: 2rem;
    width: 100%;
  }

  &.is-active {
    right: 0;
    opacity: 1;
    pointer-events: initial;
  }

  img {
    height: 60px;
  }
}

.hide-apple-blur {
  position: fixed;
  width: 100%;
  left: 0;
  height: 200px;
  top: -200px;
  background-color: var(--background);
}
</style>