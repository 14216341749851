import Vue from 'vue'
import App from './App.vue'
import router from './router'
import BootstrapVue from 'bootstrap-vue'
import VueMask from 'v-mask'
import VueGtag from "vue-gtag"
import './custom.scss'
import { rtdbPlugin } from 'vuefire'

Vue.use(rtdbPlugin)
Vue.config.productionTip = false

import BNavbar from  'bootstrap-vue'

Vue.use(BNavbar);
Vue.use(VueMask);

Vue.use(VueGtag, {
  config: { id: "UA-122339755-1" }
}, router);

new Vue({
  router,
  render: h => h(App)
}).$mount('#app')

Vue.use(BootstrapVue)
